/* banner style  */
.header-section {
  background: #13af66;
  position: relative;
  overflow-x: hidden !important;
}
.banner {
  background: #13af66;
}

.bunny-babies {
  width: 40% !important;
}

.banner-title {
  width: 50%;
  margin: auto;
}

.bunny-top {
  position: absolute;
  top: 0;
  left: 10%;
}

.bunny-top img {
  width: 100%;
}

.bunny-left {
  position: absolute;
  top: 30%;
  left: -30px;
}
.bunny-left img {
  width: 90%;
}

.bunny-right {
  position: absolute;
  top: 35%;
  right: 0;
}
.bunny-right img {
  width: 400px;
}

.bunny-right-bottom {
  position: absolute;
  bottom: 0;
  right: -40px;
}

.bunny-right-bottom img {
  width: 80%;
}

.bunny-trio {
  margin-top: 20px;
}
.bunny-trio img {
  width: 50%;
}

.out-now {
  color: #fff;
  background: #e687bf;
  border: 4px solid #046fb5;
  box-sizing: border-box;
  transform: rotate(14.3deg);
  width: 170px;
  height: 170px;
  border-radius: 50%;
  text-align: center;
  padding: 30px 15px;
  position: absolute;
  top: 15%;
  right: 15%;
}
.out-now h3 {
  font-size: 40px;
  line-height: 2rem;
}

@media only screen and (max-width: 768px) {
  .header-section {
    overflow-x: inherit !important;
    padding-bottom: 20px;
  }
  .bunny-babies {
    width: 80% !important;
  }
  .banner-title {
    width: 100%;
  }

  .bunny-top {
    width: 35%;
    left: 0;
  }

  .bunny-left,
  .bunny-right,
  .bunny-right-bottom {
    display: none;
  }

  .bunny-trio img {
    width: 80%;
  }

  .out-now {
    width: 90%;
    height: inherit;
    transform: inherit;
    border-radius: inherit;
    top: inherit;
    right: 0;
    left: 0;
    bottom: -30px;
    padding: 5px 0;
    margin: auto;
  }
  .out-now h3 {
    font-size: 20px;
    margin: 0;
    line-height: 1rem;
  }
  .out-now span {
    font-size: 14px;
    margin: 0;
  }
}
