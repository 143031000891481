.nav-bar {
}

.nav-link {
  color: #fff !important;
  font-size: 20px;
  font-family: "Passion One", cursive;
  font-weight: 400;
}
.mobile-menu {
  background: #e687bf;
  color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.nav-btn {
  display: none;
  line-height: 1.2rem;
}

@media only screen and (max-width: 768px) {
  .nav-bar {
  }
  .nav-link {
    text-align: center;
    font-size: 30px;
  }
  .nav-btn {
    display: block;
  }
}
