/* reset style  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}
ul,
li {
  padding: 0;
}

/* google fonts  */
@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Passion+One:wght@400;700&display=swap");

body {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Passion One", cursive;
  font-weight: 400;
}

.nft {
  border-radius: 1.5em;
  border: 3px solid #296FB5;
}

.nft-container {
  max-width: 33%;
  max-height: 650px;
  display: inline-block;
}

.nft-actions .dropdown-toggle {
  color: white;
}
.nft-actions .dropdown-toggle:after {
  display: none;
}