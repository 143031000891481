.footer {
  background: #13af66;
  border-top: 5px solid #046fb5;
  position: relative;
}

.footer-section {
  margin-top: -150px;
}

.bbTube {
  position: relative;
  top: -160px;
  left: 0;
  width: 40%;
}

.smart-contact {
  color: #fddf33;
  font-weight: 700;
  word-wrap: break-word;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.footer-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.footer-link {
  color: #fddf33 !important;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .footer-section {
    margin-top: -180px;
  }
  .bbTube {
    width: 100%;
    left: -40%;
  }
  .social-icons,
  .footer-links {
    justify-content: flex-start;
    gap: 20px;
  }
  .copyright {
    text-align: left;
  }
}
