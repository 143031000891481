.magic-carrot-section {
  background: #046fb5;
  color: #fff;
  border-top: 5px solid #1b1464;
  border-bottom: 5px solid #1b1464;
}

.magic-carrot {
  width: 80%;
  margin: auto;
}

.magic-carrot-title {
  font-size: 46px;
  line-height: 45px;
}

.magic-carrot-btn {
  background: #ffffff;
  color: #046fb5;
  border: 3px solid #fddf33;
  border-radius: 10px;
  padding: 5px 30px;
  font-family: "Passion One", cursive;
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .magic-carrot-row {
    flex-direction: column-reverse;
    gap: 30px;
  }
  .magic-carrot-title {
    font-size: 36px;
  }
}
