.chapter-section {
  background: #13af66;
  color: #fff;
  border-top: 5px solid #046fb5;
  position: relative;
}
.chapter-container {
  margin-top: 150px;
}

.chapter-count {
  font-family: "Passion One", sans-serif;
  font-size: 150px;
  color: #0000004d;
  font-weight: 700;
  word-wrap: break-word;
}

.chapter-title {
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
}

.chapter-number {
  color: #fddf33;
  letter-spacing: 2px;
}
.chapter-name {
  font-family: "Nunito", sans-serif;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 700;
}

.chapter-btn {
  background: #e687bf;
  color: #fff;
  border: 3px solid #046fb5;
  border-radius: 10px;
  padding: 5px 30px;
  font-family: "Passion One", cursive;
  font-size: 20px;
}

.carrot-prize {
  width: 225px;
  position: relative;
  bottom: 0px;
  left: 0;
  margin-top: -100px;
}

/* bunnies cards  */
.bunnies-card-section {
  margin-bottom: -350px;
  background: transparent !important;
}
.card {
  /* border: 2px solid #046fb5; */
  /* border-radius: 5px; */
  background: transparent;
  border: none;
}

.card img {
  width: 100%;
}

.chapter-and-cards {
  position: relative;
}

.chapter-and-cards div {
  /* this made the page go haywire */
  /* margin-top: -320px; */
}

.hand-sign {
  background: #fddf33;
  color: #046fb5;
  width: 180px;
  height: 180px;
  padding: 30px;
  border: 5px solid #046fb5;
  border-radius: 50%;
  position: relative;
  top: 250px;
  left: 85%;
  z-index: 99;
  text-align: center;
}

.hand-sign-text {
  font-family: "Passion One", cursive;
}

.hand-sign img {
  width: 100px;
  margin-bottom: 5px;
}

/* media quries  */

@media only screen and (max-width: 768px) {
  .chapter-title {
    font-size: 36px;
  }
  .chapter-count {
    font-size: 80px;
  }
  .chapter-bottom {
    flex-direction: column-reverse;
    text-align: center;
  }
  .carrot-prize {
    margin-top: inherit;
  }
  .chapter-btn {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .chapter-container {
    margin-top: 220px;
  }
  .hand-sign {
    width: 160px;
    height: 160px;
    padding: 10px;
    left: 40%;
  }
}
