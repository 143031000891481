.behind-the-screen {
  background: #1b1464;
  color: #fff;
  border-top: 5px solid #046fb5;
}

.behind-title {
  font-size: 36px;
}
.sub-title {
  color: #fddf33;
}
