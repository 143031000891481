.faq-section {
  border-top: 5px solid #046fb5;
  padding-top: 4rem;
}

.faq-section h3 {
  font-size: 45px;
  line-height: 45px;
  padding-right: 20px;
}

.faq-header button {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-weight: 700 !important;
  color: #046fb5 !important;
}
