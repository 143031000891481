.bunny-collection-section {
  background: url(../../../images//SVGs//BB-pattern-tile.svg) center center;
  padding: 120px 0 440px;
  margin-bottom: -448px;
}
.collection-box {
  background: #ffffff;
  width: 40%;
  margin: auto;
  border: 3px solid #046fb5;
  border-radius: 10px;
  padding: 40px;
  text-align: center;
}

.collection-box h3 {
  color: #046FB5;
}

.bunny-btn {
  background: #e687bf;
  color: #fff;
  border: 3px solid #046fb5;
  border-radius: 10px;
  padding: 5px 30px;
  font-family: "Passion One", cursive;
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .bunny-collection-section {
    padding: 60px 0;
  }
  .collection-box {
    width: 90%;
    padding: 10px;
  }
}
