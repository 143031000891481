/* ----------------------------------------------
 * Generated by Animista on 2021-6-25 18:36:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.anim {
    transform-origin: center;
    transform-style: preserve-3d;
    transform: perspective(800px);
    perspective-origin: center;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
.slide-in-blurred-top {
    animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
.slide-in-blurred-top2 {
    animation: slide-in-blurred-top 1.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@keyframes slide-in-blurred-top {
    0% {
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform-origin: 50% 0%;
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}


/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */

.bounce-in-top {
    animation: bounce-in-top 1.1s both;
}

@keyframes bounce-in-top {
    0% {
        transform: translateY(-500px);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateY(-65px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateY(-28px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateY(-8px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-25 18:38:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.tilt-in-top-1 {
    animation: tilt-in-top-1 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.tilt-in-top-2 {
    animation: tilt-in-top-1 .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation tilt-in-top-1
 * ----------------------------------------
 */
@keyframes tilt-in-top-1 {
    0% {
        transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
        opacity: 0;
    }
    100% {
        transform: rotateY(0deg) translateY(0) skewY(0deg);
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-6-25 18:41:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.flip-in-hor-bottom {
    animation: flip-in-hor-bottom 2.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.flip-in-hor-bottom-2 {
    animation: flip-in-hor-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
@keyframes flip-in-hor-bottom {
    0% {
        transform: rotateX(80deg);
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@keyframes puff-in-center {
    0% {
        transform: scale(2);
        filter: blur(4px);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        filter: blur(0px);
        opacity: 1;
    }
}

.puff-in-center {
    animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */

.slide-in-blurred-bottom {
    animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
.slide-in-blurred-bottom2 {
    animation: slide-in-blurred-bottom 1.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}

@keyframes slide-in-blurred-bottom {
    0% {
        transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        transform-origin: 50% 100%;
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}

/**
 * ----------------------------------------
 * animation rotate-vert-center
 * ----------------------------------------
 */
.rotate-vert-center {
    animation: rotate-vert-center 20s ease-in-out 2s infinite both;
}

@keyframes rotate-vert-center {
    0% {
        transform: rotateY(0);
    }
    25% {
        transform: rotateY(-30deg);
    }
    75% {
        transform: rotateY(30deg);
    }
    100% {
        transform: rotateY(0);
    }
}

.hovering {
    animation: hovering 3.61s cubic-bezier(.445, .05, .55, .95) infinite alternate both;
}
.hovering2 {
    animation: hovering2 3.31s cubic-bezier(.445, .05, .55, .95) infinite alternate both;
}
.hovering3 {
    animation: hovering 4.1s cubic-bezier(.445, .05, .55, .95) infinite alternate both;
}
.hovering4 {
    animation: hovering2 2.91s cubic-bezier(.445, .05, .55, .95) infinite alternate both;
}

@keyframes hovering {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-12px);
    }
}

@keyframes hovering2 {
    0% {
        transform: translateY(-12px);
    }
    100% {
        transform: translateY(0);
    }
}

.sliding {
    animation: sliding 6.123s cubic-bezier(.445, .05, .55, .95) infinite alternate both;
}

@keyframes sliding {
    0% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(-5px);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-25 9:45:34
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.swing-in-top-fwd {
    animation: swing-in-top-fwd 2.3s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */

@keyframes swing-in-top-fwd {
    0% {
        transform: rotateX(-100deg);
        transform-origin: top;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2021-11-1 11:3:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.heartbeat {
    animation: heartbeat 1.5s ease-in-out infinite both;
}

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@keyframes heartbeat {
    from {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out;
    }
    10% {
        transform: scale(0.91);
        animation-timing-function: ease-in;
    }
    17% {
        transform: scale(0.98);
        animation-timing-function: ease-out;
    }
    33% {
        transform: scale(0.87);
        animation-timing-function: ease-in;
    }
    45% {
        transform: scale(1);
        animation-timing-function: ease-out;
    }
}
